import { Breadcrumb } from '@finn/ua-auth';
import { FilterKey, GenericVehicleDetails } from '@finn/ua-vehicle';
import { getForBusinessCookie, setForBusinessCookie } from '@finn/ui-modules';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useRef } from 'react';

import getUpdatedSeoHeader from '~/contexts/Filter/utils/getUpdatedSeoHeader';
import useFilter from '~/hooks/filter/useFilter';
import {
  useFiltersResponse,
  useFilterValues,
  useMergeFilterValues,
} from '~/modules/products/filters';
import {
  CosmicObject,
  PLPPageData,
  SeoHeaderData,
} from '~/types/cosmicModules';
import { FilterLabelsData } from '~/types/cosmicModules/filterTypes';
import { AvailableFilters, Filters } from '~/types/filter';
import { PageHeadline } from '~/types/general';

export interface IFilterContextValue {
  isNoIndexNoFollow: boolean;
  canonicalUrl: string;
  advisoryContent: CosmicObject | null;
  filterSEOHeader: SeoHeaderData;
  breadcrumbs: Breadcrumb[];
  title: string;
}

export const FilterContext = createContext<IFilterContextValue>(
  {} as IFilterContextValue
);

export type FilterContextType = IFilterContextValue;

interface IProps {
  pageData?: PLPPageData;
  allAvailableFilters: AvailableFilters;
  nextAvailableFilters?: AvailableFilters;
  labelsData?: FilterLabelsData;
  vehicles?: GenericVehicleDetails[];
  vehiclesOffset?: number;
  vehiclesLimit?: number;
  totalVehiclesCount?: number;
  advisoryContentObject?: CosmicObject;
  seoHeader?: SeoHeaderData;
  headline?: PageHeadline;
  initialFilter: Filters;
  useQueryParamsOnly?: boolean;
}

const FilterProvider: React.FunctionComponent<IProps> = ({
  children,
  pageData,
  allAvailableFilters: ssrAllAvailableFilters,
  nextAvailableFilters: ssrNextAvailableFilters,
  //labelsData,
  vehicles: ssrVehicles,
  totalVehiclesCount: ssrTotalVehiclesCount,
  advisoryContentObject: ssrAdvisoryContentObject = null,
  vehiclesOffset: ssrVehiclesOffset = 0,
  seoHeader,
  headline: ssrHeadlines,
  initialFilter,
  useQueryParamsOnly,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const filters = useFilterValues();
  const mergeFilters = useMergeFilterValues();
  const allAvailableFilters = useFiltersResponse();

  const {
    nextAvailableFilters,
    totalVehiclesCount,
    isNoIndexNoFollow,
    canonicalUrl,
    advisoryContent,
    breadcrumbs,
    title,
  } = useFilter({
    ssrNextAvailableFilters,
    ssrVehicleData: {
      offset: ssrVehiclesOffset,
      results: ssrVehicles,
      total_results: ssrTotalVehiclesCount,
    },
    ssrAllAvailableFilters,
    ssrAdvisoryContentObject,
    ssrHeadlines,
    pageData,
    initialFilter,
    useQueryParamsOnly,
  });

  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const isForBusinessCookie = getForBusinessCookie();

    if (isForBusinessCookie) {
      mergeFilters({ [FilterKey.IS_FOR_BUSINESS]: true });
    }
  }, [router.isReady]);

  const isForBusinessFilter = filters[FilterKey.IS_FOR_BUSINESS];
  const isInitRef = useRef(false);

  useEffect(() => {
    if (!isInitRef.current) {
      isInitRef.current = true;

      return;
    }

    setForBusinessCookie(isForBusinessFilter ?? false);
  }, [isForBusinessFilter]);

  return (
    <FilterContext.Provider
      value={{
        isNoIndexNoFollow,
        canonicalUrl,
        advisoryContent,
        filterSEOHeader:
          getUpdatedSeoHeader({
            pageData,
            filters,
            totalVehiclesCount,
            allAvailableFilters,
            nextAvailableFilters,
            routerPath: router.asPath,
          }) || seoHeader,
        breadcrumbs,
        title,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
