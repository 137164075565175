import { FilterValuesObject } from '@finn/ua-vehicle';
import get from 'lodash/get';

import { getCustomTitleAndSeoHeader } from '~/hooks/filter/helpers';
import { PLPPageData } from '~/types/cosmicModules';
import { AvailableFilters } from '~/types/filter';
import { getInitialFilterKeysMap } from '~/utils/filter';
import {
  getMetaDataKey,
  getMinGrossPrice,
  getSeoHeader,
} from '~/utils/plp/plp';

interface IPropsGetUpdatedSeoHeader {
  pageData: PLPPageData;
  filters: FilterValuesObject;
  totalVehiclesCount: number;
  allAvailableFilters: AvailableFilters;
  nextAvailableFilters: AvailableFilters;
  routerPath: string;
}

const getUpdatedSeoHeader = ({
  pageData,
  filters,
  totalVehiclesCount,
  allAvailableFilters,
  nextAvailableFilters,
  routerPath,
}: IPropsGetUpdatedSeoHeader) => {
  const customSeoHeader = getCustomTitleAndSeoHeader(
    routerPath,
    pageData
  ).seoHeader;
  if (customSeoHeader) {
    return customSeoHeader;
  }
  const metadataKey = getMetaDataKey(filters);
  const isForBusiness = filters?.is_for_business;
  const vehiclesCount = totalVehiclesCount;
  const initialFilterKeysMap = getInitialFilterKeysMap(allAvailableFilters);
  const seoHeaderTemplate = get(pageData, `metadata.${metadataKey}`, {});

  if (metadataKey !== 'fallback_template_plp') {
    const minPrice = `${
      isForBusiness
        ? getMinGrossPrice(nextAvailableFilters.min_price, isForBusiness)
        : nextAvailableFilters.min_price
    }`;

    return getSeoHeader({
      vehiclesCount,
      minPrice,
      seoHeader: seoHeaderTemplate,
      filters,
      initialFilterKeysMap,
    });
  }

  return null;
};

export default getUpdatedSeoHeader;
