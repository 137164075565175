import { Breadcrumb } from '@finn/ua-auth';
import {
  FiltersResponse,
  FilterValuesObject,
  VehiclesResponse,
} from '@finn/ua-vehicle';
import { CosmicObject } from '@finn/ui-cosmic';
import { config } from '@finn/ui-utils';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';

import { useAdvisoryContent, useBreadcrumbs } from '~/hooks/filter/helpers';
import { useCurrentLocale } from '~/hooks/useCurrentLocale';
import {
  useAvailableFilters,
  useFilterValues,
  useIsOutOfStock,
} from '~/modules/products/filters';
import { useResultsCount } from '~/modules/products/list';
import { PLPPageData } from '~/types/cosmicModules';
import { AvailableFilters, Filters } from '~/types/filter';
import { PageHeadline } from '~/types/general';
import { getNoIndexNoFollow } from '~/utils/filter';
import { isServer } from '~/utils/general';

type FilterData = {
  isLoading: boolean;
  filters: FilterValuesObject;
  allAvailableFilters: AvailableFilters;
  nextAvailableFilters: AvailableFilters;
  totalVehiclesCount: number;
  isNoIndexNoFollow: boolean;
  canonicalUrl: string;
  advisoryContent: CosmicObject | null;
  headline: PageHeadline;
  breadcrumbs: Breadcrumb[];
  title: string;
};

interface IUserFilterProps {
  ssrAllAvailableFilters?: FiltersResponse;
  ssrNextAvailableFilters?: FiltersResponse;
  ssrVehicleData?: VehiclesResponse;
  ssrAdvisoryContentObject?: CosmicObject | null;
  ssrHeadlines?: PageHeadline;
  pageData?: PLPPageData;
  initialFilter?: Filters;
  useQueryParamsOnly?: boolean;
}

/**
 * TODO
 * As a lot of our filter logic depends on "exact" path, instead of the one provided by router.pathname
 * we may have a case after which some special "slug" can appear in the begining of the page url and mess things app.
 * But before we migrated to pathname usage, we use this additional filtering option.
 * For now we are having only "/mobile/subscribe/**" case
 */
// const filterOutHiddenSlugs = (str: string) => !str.includes('mobile');

const useFilter = ({
  ssrAllAvailableFilters,
  ssrNextAvailableFilters,
  ssrAdvisoryContentObject,
  ssrVehicleData,
  ssrHeadlines,
  pageData,
}: IUserFilterProps): FilterData => {
  const router = useRouter();
  const { locale } = useCurrentLocale();
  const [allAvailableFilters] = useState<AvailableFilters>(
    ssrAllAvailableFilters
  );

  const isOutOfStockPage = useIsOutOfStock();
  const filterValues = useFilterValues();
  const nextAvailableFilters = useAvailableFilters({
    ssrFilters: ssrNextAvailableFilters,
    isOutOfStockPage,
  });

  const { data: advisoryContent, isLoading: avisoryContentLoading } =
    useAdvisoryContent(locale, filterValues as unknown as Filters);
  const {
    headline,
    breadcrumbs,
    title,
    isLoading: breadcrumbsLoading,
  } = useBreadcrumbs({
    ssrAllAvailableFilters,
    isOutOfStockPage,
    filters: filterValues,
    routerPath: router.asPath,
    pageData,
  });

  const isLoading = avisoryContentLoading || breadcrumbsLoading;

  const totalVehiclesCount =
    useResultsCount(filterValues) || ssrVehicleData.total_results;

  const [isNoIndexNoFollow, canonicalUrl] = useMemo(() => {
    if (isServer()) return [false, '']; // return value doesn't matter, these values are not used on SSR
    const url = `${config.FINN_WEB_URL}/${locale}${
      router.asPath.split('?')[0]
    }`;

    return [
      getNoIndexNoFollow(filterValues, router.asPath, totalVehiclesCount),
      url,
    ];
  }, [locale, router.asPath, filterValues]);

  return {
    isLoading,
    filters: filterValues,
    nextAvailableFilters,
    allAvailableFilters,
    isNoIndexNoFollow,
    canonicalUrl,
    advisoryContent: advisoryContent ?? ssrAdvisoryContentObject,
    headline: headline ?? ssrHeadlines,
    breadcrumbs,
    totalVehiclesCount,
    title,
  };
};

export default useFilter;
